<template>
  <el-card>
    <div slot="header">
      <h2>{{$t('page.console.menu.menu_settings')}}</h2>
    </div>

    <div>
      <el-radio v-model="lang" label="zh-CN">中文</el-radio>
      <el-radio v-model="lang" label="en-US">English</el-radio>
    </div>


    <el-alert
        class="mt20"
        title="Header"
        type="success"
        :closable="false">
    </el-alert>

    <el-form ref="form" :model="form.header" label-width="100px" label-position="left">

      <!-- menu -->
      <el-form-item :label="$t('home')">
        <el-select v-model="form.header.home" size="small">
          <el-option
              v-for="item in menu"
              :key="item.path"
              :label="item.title"
              :value="item.path">
          </el-option>
        </el-select>
      </el-form-item>

      <!-- 走进金太阳 -->
      <el-form-item :label="$t('page.console.menu.golden_sun')">
        <el-table :data="form.header.golden_sun.list" border class="mt20">
          <el-table-column type="index" label="#"></el-table-column>

          <el-table-column label="title">
            <template slot-scope="scope">
              <el-input v-if="lang == 'zh-CN'" v-model="scope.row.title" size="small" clearable></el-input>
              <el-input v-else v-model="scope.row.en_title" size="small" clearable></el-input>
            </template>
          </el-table-column>

          <el-table-column label="url">
            <template slot-scope="scope">
              <el-input v-model="scope.row.url" size="small" clearable>
                <el-button slot="append" @click="selectView('header', 'golden_sun', scope.$index)" type="primary" size="mini" icon="el-icon-zoom-in"></el-button>
              </el-input>
            </template>
          </el-table-column>

          <el-table-column :label="$t('action')">
            <template slot-scope="scope">
              <el-button type="danger" size="mini" @click="remove('header', 'golden_sun', scope.$index)">{{$t('button.del')}}</el-button>
            </template>
          </el-table-column>

        </el-table>
        <el-button class="mt20" type="primary" plain size="mini" icon="el-icon-plus" @click="addAttrs('header', 'golden_sun')">{{$t('button.add')}}</el-button>
      </el-form-item>

      <!-- 解决方案 -->
      <el-form-item :label="$t('page.console.menu.solution')">
        <el-table :data="form.header.solution.list" border class="mt20">
          <el-table-column type="index" label="#"></el-table-column>

          <el-table-column label="title">
            <template slot-scope="scope">
              <el-input v-if="lang == 'zh-CN'" v-model="scope.row.title" size="small" clearable></el-input>
              <el-input v-else v-model="scope.row.en_title" size="small" clearable></el-input>
            </template>
          </el-table-column>

          <el-table-column label="url">
            <template slot-scope="scope">
              <el-input v-model="scope.row.url" size="small" clearable>
                <el-button slot="append" @click="selectView('header', 'solution', scope.$index)" type="primary" size="mini" icon="el-icon-zoom-in"></el-button>
              </el-input>
            </template>
          </el-table-column>

          <el-table-column :label="$t('action')">
            <template slot-scope="scope">
              <el-button type="danger" size="mini" @click="remove('header', 'solution', scope.$index)">{{$t('button.del')}}</el-button>
            </template>
          </el-table-column>

        </el-table>
        <el-button class="mt20" type="primary" plain size="mini" icon="el-icon-plus" @click="addAttrs('header', 'solution')">{{$t('button.add')}}</el-button>
      </el-form-item>

      <!-- 产品 -->
      <el-form-item :label="$t('page.console.menu.product')">
        <el-table :data="form.header.product.list" border class="mt20">
          <el-table-column type="index" label="#"></el-table-column>

          <el-table-column label="title">
            <template slot-scope="scope">
              <el-input v-if="lang == 'zh-CN'" v-model="scope.row.title" size="small" clearable></el-input>
              <el-input v-else v-model="scope.row.en_title" size="small" clearable></el-input>
            </template>
          </el-table-column>

          <el-table-column label="url">
            <template slot-scope="scope">
              <el-input v-model="scope.row.url" size="small" clearable>
                <el-button slot="append" @click="selectView('header', 'product', scope.$index)" type="primary" size="mini" icon="el-icon-zoom-in"></el-button>
              </el-input>
            </template>
          </el-table-column>

          <el-table-column :label="$t('action')">
            <template slot-scope="scope">
              <el-button type="danger" size="mini" @click="remove('header', 'product', scope.$index)">{{$t('button.del')}}</el-button>
            </template>
          </el-table-column>

        </el-table>
        <el-button class="mt20" type="primary" plain size="mini" icon="el-icon-plus" @click="addAttrs('header', 'product')">{{$t('button.add')}}</el-button>
      </el-form-item>

      <!-- 技术支持 -->
      <el-form-item :label="$t('page.console.menu.technical_support')">
        <el-table :data="form.header.technical_support.list" border class="mt20">
          <el-table-column type="index" label="#"></el-table-column>

          <el-table-column label="title">
            <template slot-scope="scope">
              <el-input v-if="lang == 'zh-CN'" v-model="scope.row.title" size="small" clearable></el-input>
              <el-input v-else v-model="scope.row.en_title" size="small" clearable></el-input>
            </template>
          </el-table-column>

          <el-table-column label="url">
            <template slot-scope="scope">
              <el-input v-model="scope.row.url" size="small" clearable>
                <el-button slot="append" @click="selectView('header', 'technical_support', scope.$index)" type="primary" size="mini" icon="el-icon-zoom-in"></el-button>
              </el-input>
            </template>
          </el-table-column>

          <el-table-column :label="$t('action')">
            <template slot-scope="scope">
              <el-button type="danger" size="mini" @click="remove('header', 'technical_support', scope.$index)">{{$t('button.del')}}</el-button>
            </template>
          </el-table-column>

        </el-table>
        <el-button class="mt20" type="primary" plain size="mini" icon="el-icon-plus" @click="addAttrs('header', 'technical_support')">{{$t('button.add')}}</el-button>
      </el-form-item>

      <!-- 投资者 -->
      <el-form-item :label="$t('page.console.menu.investor')">
        <el-table :data="form.header.investor.list" border class="mt20">
          <el-table-column type="index" label="#"></el-table-column>

          <el-table-column label="title">
            <template slot-scope="scope">
              <el-input v-if="lang == 'zh-CN'" v-model="scope.row.title" size="small" clearable></el-input>
              <el-input v-else v-model="scope.row.en_title" size="small" clearable></el-input>
            </template>
          </el-table-column>

          <el-table-column label="url">
            <template slot-scope="scope">
              <el-input v-model="scope.row.url" size="small" clearable>
                <el-button slot="append" @click="selectView('header', 'investor', scope.$index)" type="primary" size="mini" icon="el-icon-zoom-in"></el-button>
              </el-input>
            </template>
          </el-table-column>

          <el-table-column :label="$t('action')">
            <template slot-scope="scope">
              <el-button type="danger" size="mini" @click="remove('header', 'investor', scope.$index)">{{$t('button.del')}}</el-button>
            </template>
          </el-table-column>

        </el-table>
        <el-button class="mt20" type="primary" plain size="mini" icon="el-icon-plus" @click="addAttrs('header', 'investor')">{{$t('button.add')}}</el-button>
      </el-form-item>

      <!-- 媒体 -->
      <el-form-item :label="$t('page.console.menu.media')">
        <el-table :data="form.header.media.list" border class="mt20">
          <el-table-column type="index" label="#"></el-table-column>

          <el-table-column label="title">
            <template slot-scope="scope">
              <el-input v-if="lang == 'zh-CN'" v-model="scope.row.title" size="small" clearable></el-input>
              <el-input v-else v-model="scope.row.en_title" size="small" clearable></el-input>
            </template>
          </el-table-column>

          <el-table-column label="url">
            <template slot-scope="scope">
              <el-input v-model="scope.row.url" size="small" clearable>
                <el-button slot="append" @click="selectView('header', 'media', scope.$index)" type="primary" size="mini" icon="el-icon-zoom-in"></el-button>
              </el-input>
            </template>
          </el-table-column>

          <el-table-column :label="$t('action')">
            <template slot-scope="scope">
              <el-button type="danger" size="mini" @click="remove('header', 'media', scope.$index)">{{$t('button.del')}}</el-button>
            </template>
          </el-table-column>

        </el-table>
        <el-button class="mt20" type="primary" plain size="mini" icon="el-icon-plus" @click="addAttrs('header', 'media')">{{$t('button.add')}}</el-button>
      </el-form-item>

      <!-- 子公司 -->
      <el-form-item :label="$t('page.product.subsidiary')">
        <el-table :data="form.header.subsidiary.list" border class="mt20">
          <el-table-column type="index" label="#"></el-table-column>

          <el-table-column label="title">
            <template slot-scope="scope">
              <el-input v-if="lang == 'zh-CN'" v-model="scope.row.title" size="small" clearable></el-input>
              <el-input v-else v-model="scope.row.en_title" size="small" clearable></el-input>
            </template>
          </el-table-column>

          <el-table-column label="url">
            <template slot-scope="scope">
              <el-input v-model="scope.row.url" size="small" clearable>
                <el-button slot="append" @click="selectView('header', 'subsidiary', scope.$index)" type="primary" size="mini" icon="el-icon-zoom-in"></el-button>
              </el-input>
            </template>
          </el-table-column>

          <el-table-column :label="$t('action')">
            <template slot-scope="scope">
              <el-button type="danger" size="mini" @click="remove('header', 'subsidiary', scope.$index)">{{$t('button.del')}}</el-button>
            </template>
          </el-table-column>

        </el-table>
        <el-button class="mt20" type="primary" plain size="mini" icon="el-icon-plus" @click="addAttrs('header', 'subsidiary')">{{$t('button.add')}}</el-button>
      </el-form-item>
    </el-form>

    <el-alert
        title="Footer"
        type="warning"
        :closable="false">
    </el-alert>

    <!-- footer -->
    <el-form ref="form" :model="form.footer" label-width="80px" label-position="left">

      <!-- 解决方案 -->
      <el-form-item :label="$t('page.console.menu.solution')">
        <el-table :data="form.footer.solution.list" border class="mt20">
          <el-table-column type="index" label="#"></el-table-column>

          <el-table-column label="title">
            <template slot-scope="scope">
              <el-input v-if="lang == 'zh-CN'" v-model="scope.row.title" size="small" clearable></el-input>
              <el-input v-else v-model="scope.row.en_title" size="small" clearable></el-input>
            </template>
          </el-table-column>

          <el-table-column label="url">
            <template slot-scope="scope">
              <el-input v-model="scope.row.url" size="small" clearable>
                <el-button slot="append" @click="selectView('footer', 'solution', scope.$index)" type="primary" size="mini" icon="el-icon-zoom-in"></el-button>
              </el-input>
            </template>
          </el-table-column>

          <el-table-column :label="$t('action')">
            <template slot-scope="scope">
              <el-button type="danger" size="mini" @click="remove('footer', 'solution', scope.$index)">{{$t('button.del')}}</el-button>
            </template>
          </el-table-column>

        </el-table>
        <el-button class="mt20" type="primary" plain size="mini" icon="el-icon-plus" @click="addAttrs('footer', 'solution')">{{$t('button.add')}}</el-button>
      </el-form-item>

      <!-- 产品中心 -->
      <el-form-item :label="$t('page.console.menu.product')">
        <el-table :data="form.footer.product.list" border class="mt20">
          <el-table-column type="index" label="#"></el-table-column>

          <el-table-column label="title">
            <template slot-scope="scope">
              <el-input v-if="lang == 'zh-CN'" v-model="scope.row.title" size="small" clearable></el-input>
              <el-input v-else v-model="scope.row.en_title" size="small" clearable></el-input>
            </template>
          </el-table-column>

          <el-table-column label="url">
            <template slot-scope="scope">
              <el-input v-model="scope.row.url" size="small" clearable>
                <el-button slot="append" @click="selectView('footer', 'product', scope.$index)" type="primary" size="mini" icon="el-icon-zoom-in"></el-button>
              </el-input>
            </template>
          </el-table-column>

          <el-table-column :label="$t('action')">
            <template slot-scope="scope">
              <el-button type="danger" size="mini" @click="remove('footer', 'product', scope.$index)">{{$t('button.del')}}</el-button>
            </template>
          </el-table-column>

        </el-table>
        <el-button class="mt20" type="primary" plain size="mini" icon="el-icon-plus" @click="addAttrs('footer', 'product')">{{$t('button.add')}}</el-button>
      </el-form-item>

      <!-- 关于我们 -->
      <el-form-item :label="$t('page.console.menu.about_us')">
        <el-table :data="form.footer.about.list" border class="mt20">
          <el-table-column type="index" label="#"></el-table-column>

          <el-table-column label="title">
            <template slot-scope="scope">
              <el-input v-if="lang == 'zh-CN'" v-model="scope.row.title" size="small" clearable></el-input>
              <el-input v-else v-model="scope.row.en_title" size="small" clearable></el-input>
            </template>
          </el-table-column>

          <el-table-column label="url">
            <template slot-scope="scope">
              <el-input v-model="scope.row.url" size="small" clearable>
                <el-button slot="append" @click="selectView('footer', 'about', scope.$index)" type="primary" size="mini" icon="el-icon-zoom-in"></el-button>
              </el-input>
            </template>
          </el-table-column>

          <el-table-column :label="$t('action')">
            <template slot-scope="scope">
              <el-button type="danger" size="mini" @click="remove('footer', 'about', scope.$index)">{{$t('button.del')}}</el-button>
            </template>
          </el-table-column>

        </el-table>
        <el-button class="mt20" type="primary" plain size="mini" icon="el-icon-plus" @click="addAttrs('footer', 'about')">{{$t('button.add')}}</el-button>
      </el-form-item>

      <!-- 联系我们 -->
      <el-form-item :label="$t('page.console.menu.contact_us')">

        <el-form-item label="Tel">
          <el-table :data="form.footer.contact.list" border class="mt20">
            <el-table-column type="index" label="#"></el-table-column>

            <el-table-column label="tel">
              <template slot-scope="scope">
                <el-input v-if="lang == 'zh-CN'" v-model="scope.row.title" size="small" clearable></el-input>
                <el-input v-else v-model="scope.row.en_title" size="small" clearable></el-input>
              </template>
            </el-table-column>
            <el-table-column :label="$t('action')">
              <template slot-scope="scope">
                <el-button type="danger" size="mini" @click="remove('footer', 'contact', scope.$index)">{{$t('button.del')}}</el-button>
              </template>
            </el-table-column>

          </el-table>
          <el-button class="mt20" type="primary" plain size="mini" icon="el-icon-plus" @click="addAttrs('footer', 'contact')">{{$t('button.add')}}</el-button>
        </el-form-item>

        <el-form-item label="E-mail">
          <el-input v-model="form.footer.contact.email" size="small" clearable></el-input>
        </el-form-item>
        <el-form-item label="Address">
          <el-input v-if="lang == 'zh-CN'" v-model="form.footer.contact.address" size="small" clearable></el-input>
          <el-input v-else v-model="form.footer.contact.en_address" size="small" clearable></el-input>
        </el-form-item>
      </el-form-item>

      <el-form-item :label="$t('page.console.menu.copyright')">
        <el-form-item label="Info">
          <el-input v-if="lang == 'zh-CN'" v-model="form.footer.copyright.name" size="small" clearable></el-input>
          <el-input v-else v-model="form.footer.copyright.en_name" size="small" clearable></el-input>
        </el-form-item>
        <el-form-item label="Url">
          <el-input v-model="form.footer.copyright.url" size="small" clearable></el-input>
        </el-form-item>
      </el-form-item>
    </el-form>

    <el-button class="right mt20 mb20" size="mini" type="primary" @click="onSave">{{$t('button.save')}}</el-button>

    <el-dialog :visible.sync="selectPostDialogVisible" :title="$t('tips.select')">
      <select-post @select="select"></select-post>
    </el-dialog>
  </el-card>
</template>

<script>
import config from '@/config'
import {updateOption, getOption} from '@/api/os/admin';
import selectPost from '@/view/os/console/SelectPost'

export default {
  name: 'app-header',
  components: {
    selectPost
  },
  data() {
    return {
      optionName: 'page-menu',
      lang: 'zh-CN',
      selectPostDialogVisible: false,
      meta: '',
      type: '',
      index: 0,
      options: config.default.url,
      form: {
        header: {
          home: '',
          golden_sun: {
            list: []
          },
          solution: {
            list: []
          },
          product: {
            list: []
          },
          technical_support: {
            list: []
          },
          investor: {
            list: []
          },
          media: {
            list: []
          },
          subsidiary: {
            list: []
          }
        },
        footer: {
          solution: {
            list: []
          },
          product: {
            list: []
          },
          about: {
            list: []
          },
          contact: {
            list: [],
            email: '',
            address: '',
            en_address: ''
          },
          copyright: {
            name: '',
            en_name: '',
            url: ''
          }
        }
      }
    }
  },
  computed: {
    menu() {
      return this.$t('menu');
    }
  },
  created() {
    this.get()
  },
  methods: {
    remove(type, name, index) {
      this.form[type][name].list.splice(index, 1)
    },

    addAttrs(type, name) {
      if(!this.form[type][name]) {
        let data = {
          list: [],
          title: '',
          desc: ''
        }

        this.$set(this.form[type], name, data);
      }

      return this.form[type][name].list.push({})
    },
    get() {
      getOption({
        name: this.optionName,
      }).then(res => {
        if(res.data.data) {
          // if(!res.data.data.footer.copyright) {
          //   res.data.data.footer.copyright = {
          //     name: '',
          //     en_name: '',
          //     url: ''
          //   }
          // }
          this.form = res.data.data
        }
      })
    },
    onSave() {
      updateOption({
        name: this.optionName,
        value: this.form
      }).then(res => {
        this.$message.success(this.$t('tips.success'))
      })
    },

    selectView(meta, type, index) {
      this.meta = meta
      this.type = type
      this.index = index
      this.selectPostDialogVisible = true
    },
    select(data) {
      let url = data.url
      this.$set(this.form[this.meta][this.type].list[this.index], 'url', url)
      this.selectPostDialogVisible = false
    }
  }
}
</script>